<template>
  <div v-if="isAuthenticated && isSage">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><md-icon style="color: black">feedback</md-icon>&nbsp;&nbsp;&nbsp;Feedback
      </h3></div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">

      <div class="cell">

        <md-table v-model="searched" md-sort="createdDateStamp" md-sort-order="desc" md-card md-fixed-header
                  @md-selected="onSelect" :md-selected-value.sync="selectedRows" :style="tableStyle">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x">
              <div class="cell large-4 medium-4 small-6">
                <p class="md-title ai-number">{{searched.length}} <span v-if="searched.length == 1">Eintrag</span><span v-else>Einträge</span></p>
                <p v-if="allFeedback.length - searched.length > 0" style="color: crimson; margin: 0; padding-left: 10px;">{{allFeedback.length - searched.length}} nicht sichtbar
                  <md-tooltip style="font-size: medium; height: auto; white-space: pre-wrap;" md-direction="bottom" md-delay="500">
                    <b>Warum nicht sichtbar?</b><br><br>
                    <span>Durch setzen von Filtern wird die Liste eingeschränkt.</span><br>
                    <span>Wählen Sie z.B. <i style="color: greenyellow">+ Erledigte</i> um mehr Einträge zu sehen.</span>
                  </md-tooltip>
                </p>
                <p v-else style="color: seagreen; margin: 0; padding-left: 10px;">
                  Alle sichtbar
                </p>
              </div>
              <div class="cell large-2 medium-3 small-6">
                <div v-if="sending">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
                <div v-else>
                  <span>
                    <md-checkbox class="md-primary" v-model="withDone" @change="searchOnTable">+ Erledigte</md-checkbox>
                  </span>
                </div>
              </div>
              <div class="cell large-6 medium-5 small-12">
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche nach Titel ..." v-model="search" @input="searchOnTable"/>
                </md-field>
              </div>

              <div class="cell" style="padding-bottom: 15px;">
                <div class="grid-x">
                  <div class="cell medium-6 large-6 hide-for-small-only">
                  </div>
                  <div class="cell medium-6 large-6">
                    <md-button v-if="isSage" @click="onEditFeedback"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-third">
                      <md-icon>edit</md-icon>
                      <md-tooltip>Feedback bearbeiten</md-tooltip>
                    </md-button>
                    <md-button v-if="isSage" @click="onDeleteFeedback"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>delete</md-icon>
                      <md-tooltip>Feedback löschen</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </div>

            </div>
          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keine passendes Feedback gefunden"
                                :md-description="`Kein Feedback mit Titel '${search}' gefunden. Versuchen Sie es mit einem anderen Titel.`">
          </md-table-empty-state>

          <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.id)" md-selectable="single">
            <md-table-cell md-label="Kunden-Nummer" md-sort-by="customerNo">{{ item.customerNo }}
            </md-table-cell>
            <md-table-cell md-label="Ticket-Nr." md-sort-by="ticketNo">{{ item.ticketNo }}
            </md-table-cell>
            <md-table-cell md-label="erstellt am" md-sort-by="createdDateStamp">{{ item.createdDate | moment("L") }}
            </md-table-cell>
            <md-table-cell md-label="erstellt von" md-sort-by="createdBy">{{ item.createdBy }}
            </md-table-cell>
            <md-table-cell md-label="Titel" md-sort-by="title">{{ item.title }}
              <md-tooltip style="font-size: medium; height: auto; max-width: 750px; white-space: pre-wrap;" md-direction="top" md-delay="500">
                <b>Beschreibung</b>:<br><br>
                <span>{{item.text}}</span>
              </md-tooltip>
            </md-table-cell>
            <md-table-cell md-label="Typ" md-sort-by="feeType">{{ labels.feedbackType[item.type] }}
            </md-table-cell>
            <md-table-cell md-label="Prio" md-sort-by="priority">{{ labels.feedbackPriority[item.priority] }}
            </md-table-cell>
            <md-table-cell md-label="Status" md-sort-by="status">{{ labels.feedbackStatus[item.status] }}
            </md-table-cell>
            <md-table-cell md-label="Alter (Tage)" md-sort-by="ageInHours">{{ Math.round(item.ageInHours / 24) }}
            </md-table-cell>
          </md-table-row>
        </md-table>

      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteFeedbackDialog">
      <div v-if="selectedFeedback" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Feedback mit Ticket-Nr. <b>{{this.selectedFeedback.ticketNo}}</b> löschen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll das Feedback <span class="feedback-title"><b>{{this.selectedFeedback.title}}</b></span> wirklich gelöscht werden?
          </md-content>
        </div>
        <div class="cell text-center">
          <div @click="deleteFeedback" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Feedback jetzt löschen
          </div>
        </div>
        <button class="close-button" type="button" @click="showDeleteFeedbackDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <EditFeedbackDialog :feedback="selectedFeedback" :showDialog="showEditFeedbackDialog" :mode="mode"
                        @closeEditFeedbackDialog="closeEditFeedbackDialog"
                        @updateFeedbackSuccess="updateFeedbackSuccess"></EditFeedbackDialog>

  </div>

</template>

<script>
  import Vue from 'vue';
  import Spinner from 'vue-simple-spinner'
  import Feedback from '../entities/Feedback';
  import EditFeedbackDialog from '../components/dialogs/EditFeedbackDialog';
  import FeedbackService from '../services/FeedbackService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import jQuery from "jquery";

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByTitle = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.title).includes(toLower(term)));
    }

    return items;
  };

  const removeByStatus = (items, term) => {
    if (term) {
      return items.filter(item => !toLower(item.status).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'Feedback',
    components: {
      Feedback,
      EditFeedbackDialog,
      'vue-simple-spinner': Spinner
    },

    mounted() {
      this.reloadFeedback();

      setTimeout(() => {
        jQuery('.md-table-content.md-scrollbar.md-theme-default').removeAttr('style');
      }, 500);
    },

    data() {
      return {
        sending: false,
        success: false,

        allFeedback: [],
        searched: [],
        search: null,
        selected: null,
        selectedFeedback: null,
        selectedRows: [],

        showEditFeedbackDialog: false,
        showDeleteFeedbackDialog: false,
        mode: 'add',
        withDone: false,
      }
    },

    methods: {

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      reloadFeedback() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isSage) {
              this.getAllFeedback();
            }
            clearInterval(reloadIntervalId);
          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getAllFeedback() {
        this.sending = true;
        FeedbackService.getAllFeedback()
          .then(response => {
            this.allFeedback = response.data;
            this.buildFeedbackDetails();
            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Feedback-Übersicht ');
            this.sending = false;
          })
      },

      buildFeedbackDetails() {
        this.allFeedback.forEach(feedback => {
          feedback.createdDateStamp = '';
          if (feedback.createdDate) {
            feedback.createdDateString = Vue.moment(feedback.createdDate).format('dddd DD.MM.YYYY LTS') + ' Uhr';
            feedback.createdDateSortString = Vue.moment(feedback.createdDate).format('DD.MM.YYYY LTS');
            feedback.createdDateStamp = Vue.moment(feedback.createdDate).valueOf();
          }
        })
      },

      searchOnTable() {
        this.searched = searchByTitle(this.allFeedback, this.search);
        if (!this.withDone) {
          this.searched = removeByStatus(this.searched, 'CLOSED');
        }
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      resetStates() {
        this.success = false;
      },

      onSelect(item) {
        if (item && item.id) {
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedFeedback = JSON.parse(JSON.stringify(this.selected));
        } else {
          this.selected = null;
          this.selectedFeedback = null;
        }
      },

      onDeleteFeedback() {
        if (this.selected) {
          this.showDeleteFeedbackDialog = true;
        }
      },

      deleteFeedback() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          FeedbackService.deleteFeedback(this.selectedFeedback.ticketNo)
            .then(response => {
              console.log(response);
              this.sending = false;
              this.$store.commit('successMsg', 'Das Feedback mit Ticket-Nr.&nbsp;<b>' + this.selectedFeedback.ticketNo + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.success = true;
              this.selected = null;
              this.search = null;
              this.selectedFeedback = null;
              this.showDeleteFeedbackDialog = false;
              this.reloadGroups();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Feedback mit Ticket-Nr. ' + this.selectedFeedback.ticketNo);
              this.sending = false;
            })
        }
      },

      onEditFeedback() {
        if (this.selected) {
          this.mode = 'update';
          this.showEditFeedbackDialog = true;
        }
      },

      closeEditFeedbackDialog() {
        this.showEditFeedbackDialog = false;
      },

      updateFeedbackSuccess() {
        this.showEditFeedbackDialog = false;
        this.$store.commit('successMsg', 'Änderungen für Feedback mit Ticket-Nr&nbsp;<b>' + this.selectedFeedback.ticketNo + '</b>&nbsp;erfolgreich gespeichert.');
        this.success = true;
        this.selected = null;
        this.selectedFeedback = null;
        this.reloadFeedback();
      }
    },

    computed: {

      tableStyle() {
        return this.$store.getters.sage ? 'min-height: 720px; height: calc(100vh - 345px); overflow-x: hidden' : 'min-height: 720px; height: calc(100vh - 349px); overflow-x: hidden'
      },

      dataAvailable() {
        return this.isAuthenticated;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      organization() {
        return this.$store.getters.organization;
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss" scoped>

  .md-dialog /deep/.md-dialog-container {
    max-width: 800px;
  }

  i {
    color: black;
  }

  .feedback-title {
    color: chocolate;
    font-weight: normal;
    font-size: larger;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-button-icon {
    font-size: 20px!important; height: 15px; color: black!important;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }
</style>
